import React, { useState, useEffect, useContext } from 'react';
import Layout from "../../components/layout";
import QuestionCard from "../../components/questionCard"
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next";
import FormTable from "../../components/formTable";
import AccentFrame from "../../components/accentFrame";
import { Box, Typography } from '@material-ui/core';
import { QuestionnaireContext } from "../../context/questionnaireContext";
import { graphql } from 'gatsby';
import theme from '../../gatsby-theme-material-ui-top-layout/theme';

export default function Part1() {
  const {t} = useTranslation();
  const [answer, setAnswer] = useState(null);
  const [nextDisabled, setNextDisable] = useState(true);
  let previousEntry = "";
  if (typeof window !== 'undefined') {
    previousEntry = localStorage.getItem("diagnostic1") || "";
  }

  const handleChange = (event) => {
    setAnswer(event.target.value)
  }

  useEffect(() => {
    if(answer !== null)
      setNextDisable(false)
      localStorage.setItem("diagnostic1", answer)
  }, [answer]);

  useEffect(() => {
    if(previousEntry !== "") {
      setAnswer(previousEntry)
    }
  }, []);

  const columns = [t("Undergo a diagnostic test"),  t("Equal importance"), t("Choose not to undergo a diagnostic test")]
  const rows = [
    [
      t("I would be comfortable with preparing to make a decision about the outcome of the pregnancy, if the diagnostic test result is positive, considering either of the following options:"),
      t("Equal importance"),
      t("I would prefer to avoid preparing to make a difficult decision about the outcome of the pregnancy.")
    ],
    [
      t("I would be comfortable with experiencing the discomfort related to use of a needle or catheter to collect the sample."),
      t("Equal importance"),
      t("I would prefer to avoid the discomfort related to use of a needle or catheter to collect the sample.")
    ],
    [
      t("I would be comfortable with experiencing the anxiety related to the potential complications of the test."),
      t("Equal importance"),
      t("I would prefer to avoid the anxiety related to the potential complications of the test.")
    ],
    [
      t("I would be comfortable with the anxiety of waiting for the results (from the time I undergo the test to the time I receive the results)."),
      t("Equal importance"),
      t( "I would prefer to avoid the anxiety of waiting for the results.")
    ],
    [
      t("I would be comfortable with preparing for the arrival of a child with a trisomy, following a positive diagnostic test."),
      t("Equal importance"),
      t("I would be comfortable with not being prepared for the arrival of a child with a trisomy.")
    ],
  ]

  const choices = [
    {value: "Yes", label:t("Undergo a diagnostic test")},
    {value:"No", label:t("Choose not to undergo a diagnostic test")}
  ]

  return (
    <Layout pageTitle={t("Should I undergo a diagnostic test?")} maxWidth={false} next="/diagnostic/part2/" nextDisabled={nextDisabled}>
      <Box marginTop={theme.spacing(0.5)}>
        <Typography variant="body1" gutterBottom={true}>
          <strong>
            {t("Here are some factors to consider and reasons that may influence your decision concerning whether or not to undergo a diagnostic test.")}
          </strong>
          <br/>
          <br/>
          {t("Think about what is most important to you.")}
          <br/>
          <br/>
          {t("Blank")}
          <br/>
          <br/>
        </Typography>
      </Box>
      <FormTable rows={rows} columns={columns} part="part4"></FormTable>
      <AccentFrame>
        <Typography>
          {t("Parents who decide to continue with the pregnancy may choose one of the following options:")}
        </Typography>
      </AccentFrame>
      <QuestionCard question={t("What is your decision?")} choices={choices} answer={answer} handleChange={handleChange}/>
      {
        answer === "Yes"
        ? <AccentFrame>
            <Typography>
              <Trans i18nKey="FollowingPositive">
                Following a positive diagnostic test, you may have to decide whether to continue with or terminate the pregnancy. To help you make this decision, we suggest that you consult the <Link to="/more-information">resources</Link> provided and discuss the situation with your partner, if applicable, and the health care professionals who support you.
              </Trans>
            </Typography>
          </AccentFrame>
        : null
      }
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
